// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/tg_user/dev/syncfast/ziperr/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-breinkrachtadvies-js": () => import("/Users/tg_user/dev/syncfast/ziperr/src/pages/breinkrachtadvies.js" /* webpackChunkName: "component---src-pages-breinkrachtadvies-js" */),
  "component---src-pages-coaching-js": () => import("/Users/tg_user/dev/syncfast/ziperr/src/pages/coaching.js" /* webpackChunkName: "component---src-pages-coaching-js" */),
  "component---src-pages-index-js": () => import("/Users/tg_user/dev/syncfast/ziperr/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-organisatieeffectiviteit-js": () => import("/Users/tg_user/dev/syncfast/ziperr/src/pages/organisatieeffectiviteit.js" /* webpackChunkName: "component---src-pages-organisatieeffectiviteit-js" */),
  "component---src-pages-organisatieontwikkeling-js": () => import("/Users/tg_user/dev/syncfast/ziperr/src/pages/organisatieontwikkeling.js" /* webpackChunkName: "component---src-pages-organisatieontwikkeling-js" */),
  "component---src-pages-page-2-js": () => import("/Users/tg_user/dev/syncfast/ziperr/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-teamontwikkeling-js": () => import("/Users/tg_user/dev/syncfast/ziperr/src/pages/teamontwikkeling.js" /* webpackChunkName: "component---src-pages-teamontwikkeling-js" */),
  "component---src-pages-traject-js": () => import("/Users/tg_user/dev/syncfast/ziperr/src/pages/traject.js" /* webpackChunkName: "component---src-pages-traject-js" */),
  "component---src-pages-verzuimbegeleiding-js": () => import("/Users/tg_user/dev/syncfast/ziperr/src/pages/verzuimbegeleiding.js" /* webpackChunkName: "component---src-pages-verzuimbegeleiding-js" */)
}

